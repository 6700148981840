import React from "react";
import Button from "antd/lib/button";
import Icon from "antd/lib/icon";

import Header from "../_components/Header";
import FoodFinder from "../../context/FoodFinder";
import StepsMap from "../_components/StepsMap";
import CheckboxTable from "../_components/CheckboxTable";
import StepRedirect from "../../utils/HOC/StepRedirect";
import { triggerScroll } from "../../utils/message";

import "./_step7.scss";

const helps = [
  "Keeping active in old age",
  "Calming sensitive stomach",
  "Picky eater",
  "Dental and Oral Health",
  "Digestion",
  "Hip & Joint mobility",
  "Skin and Coat",
  "Anxiety",
  "Immune Support",
  "Senior Care",
  "Heart Health",
  "Liver Support",
  "Growing and developing while a puppy",
  "Urinary Tract Infection",
  "Hot Spots",
  "Itching",
  "Eye Maintenance/Clean",
  "Ear Infection",
  "Anal Gland Support",
  "Hairball",
];

class Step7 extends React.PureComponent {
  static contextType = FoodFinder;

  state = {
    helpWithFood: [],
    selectIsFocus: false,
  };

  componentDidMount() {
    const { helpWithFood } = this.props.stepsData;
    if (helpWithFood) this.setState({ helpWithFood });
  }

  onClickNext = () => {
    triggerScroll();
    this.context.setData({ ...this.state });
    this.props.history.push("complete");
  };

  render() {
    const { name, gender } = this.props.stepsData;

    return (
      <div className="step-page-container">
        <StepsMap
          currentStep={6}
          lastStep={this.context.getLastStep()}
          navigation={this.props.history}
        />
        <div className="page-container small-title">
          <span
            className="back-link"
            onClick={() => {
              this.props.history.push("step6");
            }}
          >
            <Icon type="left" style={{ fontSize: "16px" }} />
            <span>{" BACK"}</span>
          </span>
          <Header
            title={`Is there anything in particular you’d like ${name}’s new food to help with?`}
            subTitle="step 7 of 7"
          />
          <p>
            {`We can’t stop ${
              gender === "male" ? "him" : "her"
            } barking at the mailman, but we can help
            with a lot of other things.`}
          </p>
          <CheckboxTable
            values={helps}
            checkedValues={this.state.helpWithFood}
            onChange={(change) => {
              this.setState({ helpWithFood: change });
            }}
          />
          <Button type="primary" onClick={this.onClickNext}>
            Next
          </Button>
        </div>
      </div>
    );
  }
}

export default StepRedirect(Step7);
