import React from "react";
import PropTypes from "prop-types";

import style from "./header.scss";

function Header({ title, subTitle, description }) {
  return (
    <header className={style.header}>
      <h2>{subTitle}</h2>
      <h1>{title} </h1>
      <p> {description} </p>
    </header>
  );
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  description: PropTypes.string
};

export default Header;
