import React from "react";
import Button from "antd/lib/button";
import Icon from "antd/lib/icon";

import Header from "../_components/Header";
import FoodFinder from "../../context/FoodFinder";
import StepsMap from "../_components/StepsMap";
import SelectCard from "../_components/SelectCard";
import StepRedirect from "../../utils/HOC/StepRedirect";

import imagePotato from "../../assets/images/image_sleep_step5.png";
import imageRight from "../../assets/images/image_normal_step5.png";
import imageAthlete from "../../assets/images/image_run_step5.png";
import { triggerScroll } from "../../utils/message";

class Step5 extends React.PureComponent {
  static contextType = FoodFinder;

  state = {
    currentSelectCard: "couch potato",
  };

  componentDidMount() {
    const { active } = this.props.stepsData;
    if (active) this.setState({ currentSelectCard: active });
  }

  onClickNext = () => {
    triggerScroll();
    this.context.setData({ active: this.state.currentSelectCard });
    this.props.history.push("step6");
  };

  render() {
    const { currentSelectCard } = this.state;
    const { name, gender } = this.props.stepsData;

    return (
      <div className="step-page-container">
        <StepsMap
          currentStep={4}
          lastStep={this.context.getLastStep()}
          navigation={this.props.history}
        />
        <div className="page-container">
          <span
            className="back-link"
            onClick={() => {
              this.props.history.push("step4");
            }}
          >
            <Icon type="left" style={{ fontSize: "16px" }} />
            <span>{" BACK"}</span>
          </span>
          <Header title={`How active is ${name}?`} subTitle="step 5 of 7" />
          <p>
            {`Go ahead, be honest — is ${gender === "male" ? "he" : "she"} 
            literally laying on the couch now? Knowing 
            ${gender === "male" ? "his" : "her"} typical activity level helps 
            us consider how many calories
            ${gender === "male" ? "he" : "she"} might need.`}
          </p>
          <div className="cards-container">
            <SelectCard
              title="couch potato"
              description="30 minutes exercise a day or less"
              isSelected={currentSelectCard === "couch potato"}
              image={imagePotato}
              onClick={() => {
                this.setState({ currentSelectCard: "couch potato" });
              }}
            />
            <SelectCard
              title="Happy go walkie"
              description="1 hour exercise a day"
              isSelected={currentSelectCard === "just right"}
              image={imageRight}
              onClick={() => {
                this.setState({ currentSelectCard: "just right" });
              }}
            />
            <SelectCard
              title="Pawfessional Athlete"
              description="1.5 hours exercise a day or more"
              isSelected={currentSelectCard === "pawfessional athlete"}
              image={imageAthlete}
              onClick={() => {
                this.setState({ currentSelectCard: "pawfessional athlete" });
              }}
            />
          </div>
          <Button type="primary" onClick={this.onClickNext}>
            Next
          </Button>
        </div>
      </div>
    );
  }
}

export default StepRedirect(Step5);
