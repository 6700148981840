import React from "react";
import PropTypes from "prop-types";
import Checkbox from "antd/lib/checkbox";

import "./_checkboxTable.scss";

function CheckboxTable({
  values = [],
  checkedValues = [],
  onChange,
  className,
  checkboxClassName
}) {
  function hanldeCheckChange(value, isChecked) {
    if (isChecked) {
      onChange([...checkedValues, value]);
    } else {
      onChange(checkedValues.filter(checkValue => checkValue !== value));
    }
  }

  return (
    <div className={`checkbox-table ${className}`}>
      {values.map(value => (
        <Checkbox
          className={`${checkboxClassName ? checkboxClassName : ""}`}
          key={value}
          onChange={e => {
            hanldeCheckChange(value, e.target.checked);
          }}
          checked={checkedValues.includes(value)}
        >
          <div
            tabindex={0}
            onKeyPress={e => {
              if (e.key === "Enter") {
                hanldeCheckChange(value, !checkedValues.includes(value));
              }
            }}
          >
            {value}
          </div>
        </Checkbox>
      ))}
      {values.length % 2 !== 0 && (
        <div className="ant-checkbox-wrapper checkbox-wrapper-empty" />
      )}
    </div>
  );
}

CheckboxTable.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  checkedValues: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func, //return checked values
  className: PropTypes.string,
  checkboxClassName: PropTypes.string
};

export default React.memo(CheckboxTable);
