import React from "react";
import Button from "antd/lib/button";
import Select from "antd/lib/select";
import Icon from "antd/lib/icon";
import USAMap from "react-usa-map";

import Header from "../_components/Header";
import FoodFinder from "../../context/FoodFinder";
import StepsMap from "../_components/StepsMap";
import StepRedirect from "../../utils/HOC/StepRedirect";
import UsStates from "../../assets/data/us-states.json";

import { triggerScroll } from "../../utils/message";
import "./_step4.scss";

const { Option } = Select;

class Step4 extends React.PureComponent {
  static contextType = FoodFinder;

  state = {
    selectedCountry: "",
    selectIsFocus: false,
    isNextClicked: false,
  };

  componentDidMount() {
    const { country } = this.props.stepsData;
    if (country) this.setState({ selectedCountry: country });

    const pathElements = document.querySelectorAll("path");

    pathElements.forEach((path) => {
      path.tabIndex = 0;
      path.onkeypress = this.mapTabKeyHandler;
    });

    //remove dot on map
    document.querySelectorAll("circle")[0].style.display = "none";
  }

  onClickNext = () => {
    if (this.state.selectedCountry !== "") {
      triggerScroll();
      this.context.setData({ country: this.state.selectedCountry });
      this.props.history.push("step5");
    } else {
      this.setState({ isNextClicked: true });
    }
  };

  handleSelectedCountry = (selectedCountry) => {
    this.setState({ selectedCountry });
  };

  mapTabKeyHandler = (event) => {
    if (event.key === "Enter") {
      this.mapHandler(event);
    }
  };

  /* mandatory */
  mapHandler = (event) => {
    this.setState({ selectedCountry: event.target.dataset.name });
  };

  /* optional customization of filling per state and calling custom callbacks per state */
  statesCustomConfig = () => {
    return {
      [this.state.selectedCountry]: {
        fill: "#0D9DDB",
      },
    };
  };

  render() {
    return (
      <div className="step-page-container step4">
        <StepsMap
          currentStep={3}
          lastStep={this.context.getLastStep()}
          navigation={this.props.history}
        />
        <div className="page-container">
          <span
            className="back-link"
            onClick={() => {
              this.props.history.push("step3");
            }}
          >
            <Icon type="left" style={{ fontSize: "16px" }} />
            <span>{" BACK"}</span>
          </span>
          <Header title="Where do you live?" subTitle="step 4 of 7" />
          <p>
            {`Don’t worry, we don’t need your door number. Just an idea of the climate ${this.props.stepsData.name} 
            is living in - are they trekking through the snow in Alaska? Lazing in the California sun? 
            Different climates call for different pet diets, so find your state below.`}
          </p>
          <div className="map-container">
            <USAMap
              customize={this.statesCustomConfig()}
              onClick={this.mapHandler}
              defaultFill="#E1E1E1"
              width="100%"
              height="auto"
              title=""
            />
          </div>
          <div className="select-container">
            <Select
              style={{
                marginBottom:
                  this.state.selectIsFocus && window.innerWidth <= 414
                    ? "250px"
                    : "0px",
              }}
              showSearch
              placeholder="Select Your State"
              optionFilterProp="children"
              onChange={this.handleSelectedCountry}
              value={this.state.selectedCountry}
              filterOption={(input, option) =>
                option.props.children
                  ? option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  : true
              }
              onDropdownVisibleChange={(open) =>
                window.innerWidth <= 414 &&
                this.setState({ selectIsFocus: open })
              }
              notFoundContent="No results found..."
            >
              {UsStates.map((state) => (
                <Option key={state.abbreviation} value={state.abbreviation}>
                  {state.name}
                </Option>
              ))}
            </Select>
          </div>
          {this.state.isNextClicked && this.state.selectedCountry === "" && (
            <div class="has-error ant-form-explain">
              Please select the state!
            </div>
          )}
          <Button type="primary" onClick={this.onClickNext}>
            Next
          </Button>
        </div>
      </div>
    );
  }
}

export default StepRedirect(Step4);
