import React from "react";
import PropTypes from "prop-types";

import "./_select-card.scss";

import checkImage from "../../../assets/images/check.png";

function SelectCard({ image, title, description, isSelected, onClick }) {
  const handleKeyPress = event => {
    if (event.key === "Enter") {
      onClick();
    }
  };

  return (
    <div
      className={`select-card ${isSelected ? "selected" : ""}`}
      tabindex={0}
      onClick={onClick}
      onKeyPress={handleKeyPress}
    >
      <div className="check">
        <img src={checkImage} alt="check" />
      </div>
      <div
        className="image-container"
        style={{ height: `${328 - (description ? 80 : 40)}px` }}
      >
        <img src={image} alt={`${title} dog`} />
      </div>
      <span className="title">{title}</span>
      {description && <span className="description">{description}</span>}
    </div>
  );
}

SelectCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func
};

export default SelectCard;
