import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./Home";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step7 from "./Step7";
import Complete from "./Complete";

export default function Pages() {
  return (
    <Router>
      <Switch>
        <Route exact path="/step1" component={Step1} />
        <Route exact path="/step2" component={Step2} />
        <Route exact path="/step3" component={Step3} />
        <Route exact path="/step4" component={Step4} />
        <Route exact path="/step5" component={Step5} />
        <Route exact path="/step6" component={Step6} />
        <Route exact path="/step7" component={Step7} />
        <Route exact path="/complete" component={Complete} />
        <Route exact path="/" component={Home} />
        <Route
          path="/*"
          component={props => {
            props.history.push("/"); //redirect to home
            return <div></div>;
          }}
        />
      </Switch>
    </Router>
  );
}
