import React from "react";
import { Form, Select } from "antd";
import Button from "antd/lib/button";
import Icon from "antd/lib/icon";

import Header from "../_components/Header";
import StepsMap from "../_components/StepsMap";
import FoodFinder from "../../context/FoodFinder";
import StepRedirect from "../../utils/HOC/StepRedirect";

import introImage from "../../assets/images/image_step2.png";

import breeds from "../../assets/data/breeds.json";
import { triggerScroll } from "../../utils/message";

const { Option } = Select;

class Step2 extends React.PureComponent {
  static contextType = FoodFinder;

  state = {
    selectIsFocus: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        triggerScroll();
        this.context.setData({ breed: values.breed });
        this.props.history.push("step3");
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { breed, name, gender } = this.props.stepsData;

    return (
      <div className="step-page-container">
        <StepsMap
          currentStep={1}
          lastStep={this.context.getLastStep()}
          navigation={this.props.history}
        />
        <div className="page-container">
          <span
            className="back-link"
            onClick={() => {
              this.props.history.push("step1");
            }}
          >
            <Icon type="left" style={{ fontSize: "16px" }} />
            <span>{" BACK"}</span>
          </span>
          <Header title={`What breed is ${name}?`} subTitle="step 2 of 7" />
          <p>
            {`We know no two dogs are the same, but ${name}’s breed tells us a lot
            about ${gender === "male" ? "his" : "her"} needs.`}
          </p>
          <img className="intro-image" src={introImage} alt="dog" />
          <Form onSubmit={this.handleSubmit}>
            <Form.Item>
              {getFieldDecorator("breed", {
                rules: [
                  { required: true, message: "Please select dog breed!" },
                ],
                initialValue: breed,
              })(
                <Select
                  style={{
                    marginBottom:
                      this.state.selectIsFocus && window.innerWidth <= 414
                        ? "250px"
                        : "0px",
                  }}
                  showSearch
                  placeholder="Select Dog Breed"
                  optionFilterProp="children"
                  firstActiveValue={breed}
                  filterOption={(input, option) =>
                    option.props.children
                      ? option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : true
                  }
                  onDropdownVisibleChange={(open) =>
                    window.innerWidth <= 414 &&
                    this.setState({ selectIsFocus: open })
                  }
                  notFoundContent="No results found..."
                >
                  <Option value="Mixed Breed">Mixed Breed</Option>
                  <Option
                    key="line"
                    style={{
                      height: 0,
                      borderBottom: "1px dashed grey",
                      margin: "0 12px",
                      padding: 0,
                    }}
                    disabled
                  >
                    Mixed Breed
                  </Option>
                  {breeds.map((breed) => (
                    <Option key={breed.name} value={breed.name}>
                      {breed.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Next
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default Form.create({ name: "step2" })(StepRedirect(Step2));
