import React from "react";
import Button from "antd/lib/button";
import Input from "antd/lib/input";
import Icon from "antd/lib/icon";

import Header from "../_components/Header";
import FoodFinder from "../../context/FoodFinder";
import StepsMap from "../_components/StepsMap";
import CheckboxTable from "../_components/CheckboxTable";
import StepRedirect from "../../utils/HOC/StepRedirect";
import { triggerScroll } from "../../utils/message";

const { Search } = Input;

class Step6 extends React.PureComponent {
  static contextType = FoodFinder;

  state = {
    currentDiet: [],
    proteinSource: [],
    allergies: [],
    allergiesSelected: []
  };

  componentDidMount() {
    const {
      currentDiet,
      proteinSource,
      allergies,
      allergiesSelected
    } = this.props.stepsData;
    if (currentDiet || proteinSource || allergies || allergiesSelected) {
      this.setState({
        currentDiet: currentDiet || [],
        proteinSource: proteinSource || [],
        allergies: allergies || [],
        allergiesSelected: allergiesSelected || []
      });
    }
  }

  onClickNext = () => {
    triggerScroll();
    this.context.setData({ ...this.state });
    this.props.history.push("step7");
  };

  render() {
    const { currentDiet, proteinSource, allergies } = this.state;

    const { name, gender } = this.props.stepsData;

    return (
      <div className="step-page-container">
        <StepsMap
          currentStep={5}
          lastStep={this.context.getLastStep()}
          navigation={this.props.history}
        />
        <div className="page-container small-title">
          <span
            className="back-link"
            onClick={() => {
              this.props.history.push("step5");
            }}
          >
            <Icon type="left" style={{ fontSize: "16px" }} />
            <span>{" BACK"}</span>
          </span>
          <Header
            title={`What is ${name}’s current diet?`}
            subTitle="step 6 of 7"
          />
          <p>
            {`Not counting any shoes or furniture 
            ${gender === "male" ? "he" : "she"} may have chewed.`}
          </p>
          <CheckboxTable
            values={[
              "Dry food",
              "Freeze-dried food",
              "Wet food",
              "Home cooked",
              "Raw food",
              "Fresh food",
              "Dehydrated and air dried food"
            ]}
            checkedValues={currentDiet}
            onChange={change => {
              this.setState({ currentDiet: change });
            }}
          />
          <span className="title">What’s {name}’s main source of protein?</span>
          <p>
            Just like you fuel up before hitting the gym, pets need protein for
            energy and building muscle. What’s your dog’s main source?
          </p>
          <CheckboxTable
            values={[
              "Chicken",
              "Fish",
              "Beef",
              "Pork",
              "Salmon",
              "Lamb",
              "Duck",
              "Goat",
              "Turkey",
              "Other"
            ]}
            checkedValues={proteinSource}
            onChange={change => {
              this.setState({ proteinSource: change });
            }}
          />
          {false && (
            <React.Fragment>
              <span className="title">
                Does {name} have any food allergies?
              </span>
              <CheckboxTable
                values={["yes", "no"]}
                checkedValues={allergies}
                onChange={change => {
                  this.setState({
                    allergies: change.filter(value => value !== allergies[0])
                  });
                }}
              />
              {allergies[0] === "yes" && (
                <Search
                  placeholder="Select Allergies"
                  onSearch={value => console.log(value)}
                ></Search>
              )}
            </React.Fragment>
          )}
          <Button type="primary" onClick={this.onClickNext}>
            Next
          </Button>
        </div>
      </div>
    );
  }
}

export default StepRedirect(Step6);
