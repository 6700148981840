import React from "react";
import Header from "../_components/Header";
import { Form, Input } from "antd";
import Button from "antd/lib/button";

import FoodFinder from "../../context/FoodFinder";
import StepRedirect from "../../utils/HOC/StepRedirect";

import Skeleton from "antd/lib/skeleton";

import introImage from "../../assets/images/bowl.png";

import "./style.scss";

const mockData = {
  name: "Puppy",
  store_url:
    "https://loyalcompanion.com/blogs/news/with-a-bowl-like-this-who-needs-to-beg-for-table-scraps",
  base: {
    id: 2,
    name: "Base",
    image: "/media/base.jpg",
    description:
      "A complete and balanced base should provide around 90% of the nutrients your pet needs.",
    store_url:
      "https://loyalcompanion.com/products/zignature-limited-ingredient-diet-grain-free-turkey-formula-dry-dog-food%20",
  },
  hydrator: {
    id: 1,
    name: "Hydrator",
    image: "/media/hydrator.jpg",
    description:
      "A hydrator adds flavor and moisture to their food — which is important, since many pets aren’t getting enough water.",
    store_url:
      "https://loyalcompanion.com/products/the-honest-kitchen-daily-boosters-probiotic-goats-milk-liquid-treat-for-dogs-and-cats",
  },
  topper: {
    id: 3,
    name: "Topper",
    image: "/media/topper.jpg",
    description:
      "A topper doesn’t just add flavor and variety to your pet’s bowl to get them excited for dinner — it also adds extra vitamins, minerals, protein and enzymes to round out their meal.",
    store_url:
      "https://loyalcompanion.com/collections/dog/products/stella-chewys-duck-duck-goose-grain-free-dinner-patties-freeze-dried-raw-dog-food",
  },
  supplement: {
    id: 4,
    name: "Supplement",
    image: "/media/supplemtn.png",
    description:
      "We take our vitamins daily (or at least try to remember to), and so should our pets. Additional vitamins, minerals, enzymes and fiber help them live longer and happier lives.",
    store_url:
      "https://loyalcompanion.com/products/weruva-pumpkin-patch-up-supplement-for-dogs-and-cats",
  },
};

class Complete extends React.PureComponent {
  static contextType = FoodFinder;

  state = {
    bowls: undefined,
    showEmailCapture: true,
    showEmailThankYou: false,
    emailCompleted: false,
  };

  componentDidMount() {
    this.getResult();
    const { email } = this.props.stepsData;

    if (email) {
      this.setState({ showEmailCapture: true, showEmailThankYou: true });
      setTimeout(() => {
        this.setState({
          emailCompleted: true,
        });
      }, 3000);
    }
  }

  getResult = async () => {
    const stepsData = this.props.stepsData;
    const body = JSON.stringify({
      email: stepsData.email,
      name: stepsData.name,
      gender: stepsData.gender,
      age: stepsData.age,
      breed: stepsData.breed,
      weight: stepsData.weight,
      activity: stepsData.active,
      state: stepsData.country,
      current_diet: stepsData.currentDiet || [],
      protein_source: stepsData.proteinSource || [],
      allergies: [],
      help_with_food: stepsData.helpWithFood || [],
    });

    try {
      const response = await fetch("/api/v1/survey/", {
        method: "post",
        body,
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": window.CSRF_TOKEN,
        },
      });

      const responseData = await response.json();

      const result = await fetch(`/api/v1/results/${responseData.uuid}`);
      const resultData = await result.json();

      this.setState({ bowls: resultData.bowls[0] });
    } catch (err) {
      // err, set default data
      this.setState({ bowls: mockData });
    }
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.startAgain();
    }
  };

  submitFormHandler = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.context.setData(values);
        this.getResult();
        // switch mode to thank then hide after 3 seconds;
        this.setState({
          showEmailThankYou: true,
        });
        setTimeout(() => {
          this.setState({
            emailCompleted: true,
          });
        }, 3000);
      }
    });
  };

  toggleEmailHandler = (event) => {
    // show and hide email box
    const { showEmailCapture } = this.state;
    const newShowEmailCapture = !showEmailCapture;
    this.setState({
      showEmailCapture: newShowEmailCapture,
    });
  };

  startAgain = () => {
    this.props.history.push("step1");
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { bowls } = this.state;

    return (
      <>
        <div className="page-container complete">
          <div
            className="start-again-link-button"
            tabindex={0}
            onClick={this.startAgain}
            onKeyPress={this.handleKeyPress}
          >
            START AGAIN
          </div>
          <Skeleton
            loading={!bowls}
            active
            avatar={{ size: 395, shape: "sqare" }}
            paragraph={false}
            title={false}
          >
            {bowls && (
              <React.Fragment>
                <Header
                  title={`here's ${this.props.stepsData.name}'s ${bowls.name}`}
                  subTitle="complete"
                />
                <p>
                  Based on what you’ve told us,
                  {` the ${bowls.name} `.toUpperCase()}
                  will help provide your pooch what{" "}
                  {this.props.stepsData.gender === "male" ? "he" : "she"} needs
                  to stay as happy and healthy as possible. Before you view our
                  selections for your pup’s perfect meal, here are the elements
                  that make a complete, balanced bowl.
                </p>
                <div className="perfect-bowl-container">
                  <div className="perfect-bowl-description text-right desktop">
                    <div
                      className="description-title"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <div>{bowls.hydrator.name}</div>
                      <div
                        className="title-line"
                        style={{
                          width: "140px",
                          margin: "6px -140px 0 10px",
                        }}
                      />
                    </div>
                    <p>{bowls.hydrator.description}</p>
                    <div
                      className="description-title"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <div>{bowls.base.name}</div>
                      <div
                        className="title-line"
                        style={{
                          width: "60px",
                          margin: "6px -60px 0 10px",
                        }}
                      />
                    </div>
                    <p>{bowls.base.description}</p>
                  </div>
                  <img className="intro-image" src={introImage} alt="bowl" />
                  <div className="perfect-bowl-description text-left desktop">
                    <div className="description-title">
                      <div
                        className="title-line"
                        style={{
                          width: "140px",
                          marginLeft: "-150px",
                          marginRight: "10px",
                        }}
                      />
                      <div>{bowls.supplement.name}</div>
                    </div>
                    <p>{bowls.supplement.description}</p>
                    <div className="description-title">
                      <div
                        className="title-line"
                        style={{
                          width: "50px",
                          marginLeft: "-60px",
                          marginRight: "10px",
                        }}
                      />
                      <div>{bowls.topper.name}</div>
                    </div>
                    <p>{bowls.topper.description}</p>
                  </div>
                </div>
                <div className="perfect-bowl-description text-left mobile">
                  <div className="description-title">{bowls.hydrator.name}</div>
                  <p>{bowls.hydrator.description}</p>
                  <div className="description-title">{bowls.base.name}</div>
                  <p>{bowls.base.description}</p>
                  <div className="description-title">
                    {bowls.supplement.name}
                  </div>
                  <p>{bowls.supplement.description}</p>
                  <div className="description-title">{bowls.topper.name}</div>
                  <p>{bowls.topper.description}</p>
                </div>
                <a className="ant-btn" href={bowls.store_url} target="_parent">
                  <span>View the {bowls.name}</span>
                </a>
              </React.Fragment>
            )}
          </Skeleton>
          <p id="allergies-description">
            Your pet may have allergies. Please read product labels thoroughly
            and be mindful of any ingredients that should be avoided due to
            allergies.
          </p>
        </div>
        <div
          className={`${
            this.state.showEmailCapture
              ? "emailCapture"
              : "collapsed emailCapture"
          } ${this.state.emailCompleted ? "emailCompleted" : ""}`}
        >
          {!this.state.showEmailThankYou ? (
            <>
              <button
                className="plus-minus-toggle"
                onClick={this.toggleEmailHandler}
              ></button>
              <h3 onClick={this.toggleEmailHandler} className="emailHeader">
                Want wellness advice for your pet?
              </h3>
              <p>
                Sign up and we'll send you the latest product updates, tips and
                advice based on what food your pet needs to stay happy and
                healthy.
              </p>
              <Form onSubmit={this.submitFormHandler}>
                <Form.Item>
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        required: true,
                        message: "Please enter a valid email address",
                      },
                    ],
                  })(
                    <Input
                      name="email"
                      type="email"
                      placeholder="Enter your email address"
                      maxLength="40"
                      className="emailAddress"
                    />
                  )}
                  <Button
                    type="primary"
                    className="emailSubmit"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </>
          ) : (
            <h3 onClick={this.toggleEmailHandler} className="emailHeader">
              Success! We’ll be in touch.
            </h3>
          )}
        </div>
      </>
    );
  }
}

//export default StepRedirect(Complete);

export default Form.create({ name: "emailCapture" })(StepRedirect(Complete));
