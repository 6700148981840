import React from "react";
const FoodFinder = React.createContext();

export function FoodFinderProvider({ children }) {
  let data = {};
  let lastStep = 1;

  try {
    data = JSON.parse(localStorage.getItem("stepsData")) || {};
  } catch {
    localStorage.removeItem("stepsData");
  }

  function setData(newData) {
    data = { ...data, ...newData };

    localStorage.setItem("stepsData", JSON.stringify(data));
  }

  function getData() {
    return data;
  }

  function setCurrentStep(step) {
    if (step > lastStep) lastStep = step;
  }

  function getLastStep() {
    return lastStep;
  }

  return (
    <FoodFinder.Provider
      value={{ getData, setData, setCurrentStep, getLastStep }}
    >
      {children}
    </FoodFinder.Provider>
  );
}

export default FoodFinder;
