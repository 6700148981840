import React from "react";
import { Form, Select, Input } from "antd";
import Button from "antd/lib/button";

import Header from "../_components/Header";
import StepsMap from "../_components/StepsMap";
import FoodFinder from "../../context/FoodFinder";

import introImage from "../../assets/images/image_step1.png";
import { triggerScroll } from "../../utils/message";

const { Option } = Select;

class Step1 extends React.PureComponent {
  static contextType = FoodFinder;

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        triggerScroll();
        this.context.setData({ ...values, name: values.name.trim() });
        this.props.history.push("step2");
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const initialData = this.context.getData();

    return (
      <div className="step-page-container">
        <StepsMap
          currentStep={0}
          lastStep={this.context.getLastStep()}
          navigation={this.props.history}
        />
        <div className="page-container">
          <Header title="let's meet your dog" subTitle="step 1 of 7" />
          <p>First things first, let’s get introduced</p>
          <img className="intro-image" src={introImage} alt="dog" />
          <Form onSubmit={this.handleSubmit}>
            <div className="row-container">
              <Form.Item>
                {getFieldDecorator("gender", {
                  rules: [
                    { required: true, message: "Please select dog gender!" }
                  ],
                  initialValue: initialData.gender
                })(
                  <Select placeholder="Sex" onChange={this.handleSelectChange}>
                    <Option value="male">Good boy</Option>
                    <Option value="female">Good girl</Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("age", {
                  rules: [
                    { required: true, message: "Please select dog age!" }
                  ],
                  initialValue: initialData.age
                })(
                  <Select
                    placeholder="Dog Age"
                    onChange={this.handleSelectChange}
                  >
                    <Option value="0-1">Just a pup (0-1 Years)</Option>
                    <Option value="1-7">In their prime (1-7 years)</Option>
                    <Option value="7+">Older and wiser (7 years +)</Option>
                  </Select>
                )}
              </Form.Item>
            </div>
            <Form.Item>
              {getFieldDecorator("name", {
                rules: [{ required: true, message: "Please insert the name!" }],
                initialValue: initialData.name
              })(<Input placeholder="Name" maxLength="12" />)}
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Next
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default Form.create({ name: "step1" })(Step1);
