import React from "react";
import FoodFinder from "../../context/FoodFinder";

// Redirect if some step data is missing & give current stepData
function StepRedirect(WrappedComponent) {
  return class extends React.PureComponent {
    static contextType = FoodFinder;

    componentDidMount() {
      const stepData = this.context.getData();
      const currentStep = parseInt(
        this.props.location.pathname.replace("/step", "")
      );

      window.scrollTo(0, 0);

      switch (currentStep) {
        case 2:
          this.checkStep1(stepData) && this.context.setCurrentStep(currentStep);
          break;
        case 3:
          this.checkStep2(stepData) && this.context.setCurrentStep(currentStep);
          break;
        case 4:
          this.checkStep3(stepData) && this.context.setCurrentStep(currentStep);
          break;
        case 5:
          this.checkStep4(stepData) && this.context.setCurrentStep(currentStep);
          break;
        case 6:
          this.checkStep5(stepData) && this.context.setCurrentStep(currentStep);
          break;
        case 7:
          this.checkStep6(stepData) && this.context.setCurrentStep(currentStep);
          break;
        default:
          this.checkStep7(stepData);
      }
    }

    // check functions return true if they don't have a match else
    // they navigate to the exact patch were data is missing
    checkStep1 = stepData => {
      if (!stepData.gender || !stepData.age || !stepData.name) {
        this.props.history.push("step1");
        return false;
      }
      return true;
    };
    checkStep2 = stepData => {
      const checkResult = this.checkStep1(stepData);
      if (this.checkStep1(stepData) && !stepData.breed) {
        this.props.history.push("step2");
        return false;
      }
      return checkResult;
    };
    checkStep3 = stepData => {
      const checkResult = this.checkStep2(stepData);
      if (checkResult && !stepData.weight) {
        this.props.history.push("step3");
        return false;
      }
      return checkResult;
    };
    checkStep4 = stepData => {
      const checkResult = this.checkStep3(stepData);
      if (checkResult && !stepData.country) {
        this.props.history.push("step4");
        return false;
      }
      return checkResult;
    };
    checkStep5 = stepData => {
      const checkResult = this.checkStep4(stepData);
      if (checkResult && !stepData.active) {
        this.props.history.push("step5");
        return false;
      }
      return checkResult;
    };
    checkStep6 = stepData => {
      const checkResult = this.checkStep5(stepData);
      if ((checkResult && !stepData.currentDiet) || !stepData.proteinSource) {
        this.props.history.push("step6");
        return false;
      }
      return checkResult;
    };
    checkStep7 = stepData => {
      const checkResult = this.checkStep6(stepData);
      if (checkResult && !stepData.helpWithFood) {
        this.props.history.push("step7");
        return false;
      }
      return checkResult;
    };

    render() {
      return (
        <WrappedComponent {...this.props} stepsData={this.context.getData()} />
      );
    }
  };
}

export default StepRedirect;
