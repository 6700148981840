import React from "react";
import PropTypes from "prop-types";
import Steps from "antd/lib/steps";

const { Step } = Steps;

function StepsMap({ currentStep, lastStep, navigation }) {
  const getStepStatus = step => {
    if (step === currentStep + 1) {
      return "process";
    } else {
      return step <= lastStep ? "finish" : "wait";
    }
  };

  const stepNavigation = step => {
    getStepStatus(step) === "finish" && navigation.push(`step${step}`);
  };

  const handleKeyPress = (event, step) => {
    if (event.key === "Enter") {
      stepNavigation(step);
    }
  };

  return (
    <Steps direction="vertical" current={currentStep}>
      <Step
        status={getStepStatus(1)}
        onClick={() => stepNavigation(1)}
        icon={1}
        tabIndex={getStepStatus(1) === "finish" ? 0 : -1}
        onKeyPress={e => handleKeyPress(e, 1)}
      />
      <Step
        status={getStepStatus(2)}
        onClick={() => stepNavigation(2)}
        icon={2}
        tabIndex={getStepStatus(2) === "finish" ? 0 : -1}
        onKeyPress={e => handleKeyPress(e, 2)}
      />
      <Step
        status={getStepStatus(3)}
        onClick={() => stepNavigation(3)}
        icon={3}
        tabIndex={getStepStatus(3) === "finish" ? 0 : -1}
        onKeyPress={e => handleKeyPress(e, 3)}
      />
      <Step
        status={getStepStatus(4)}
        onClick={() => stepNavigation(4)}
        icon={4}
        tabIndex={getStepStatus(4) === "finish" ? 0 : -1}
        onKeyPress={e => handleKeyPress(e, 4)}
      />
      <Step
        status={getStepStatus(5)}
        onClick={() => stepNavigation(5)}
        icon={5}
        tabIndex={getStepStatus(5) === "finish" ? 0 : -1}
        onKeyPress={e => handleKeyPress(e, 5)}
      />
      <Step
        status={getStepStatus(6)}
        onClick={() => stepNavigation(6)}
        icon={6}
        tabIndex={getStepStatus(6) === "finish" ? 0 : -1}
        onKeyPress={e => handleKeyPress(e, 6)}
      />
      <Step
        status={getStepStatus(7)}
        onClick={() => stepNavigation(7)}
        icon={7}
        tabIndex={getStepStatus(7) === "finish" ? 0 : -1}
        onKeyPress={e => handleKeyPress(e, 7)}
      />
    </Steps>
  );
}

StepsMap.propTypes = {
  currentStep: PropTypes.number.isRequired
};

export default StepsMap;
