import React from "react";
import Header from "../_components/Header";
// import FoodFinder from "../../context/FoodFinder";

import Button from "antd/lib/button";

import imageHome from "../../assets/images/intro.png";

import { triggerScroll } from "../../utils/message";

function Home(props) {
  // const { data } = useContext(FoodFinder);

  return (
    <div className="page-container">
      <Header title="dog food finder" subTitle="introduction" />
      <img className="intro-image" src={imageHome} alt="dog" />
      <p>
        Want to build your best friend a complete, nutritious bowl of food?
        Answer a few quick questions and we’ll recommend a complete, balanced
        bowl that meets their specific needs.
      </p>
      <p>Let’s get to know your dog.</p>
      <Button
        onClick={() => {
          props.history.push("step1");
          triggerScroll();
        }}
      >
        Find the perfect food
      </Button>
    </div>
  );
}

export default Home;
