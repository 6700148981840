import React from "react";
import Pages from "./pages";
import { FoodFinderProvider } from "./context/FoodFinder";

function App() {
  return (
    <FoodFinderProvider>
      <Pages />
    </FoodFinderProvider>
  );
}

export default App;
