import React from "react";
import Button from "antd/lib/button";
import Icon from "antd/lib/icon";

import Header from "../_components/Header";
import FoodFinder from "../../context/FoodFinder";
import StepsMap from "../_components/StepsMap";
import SelectCard from "../_components/SelectCard";
import StepRedirect from "../../utils/HOC/StepRedirect";

import underweightImage from "../../assets/images/image_underweight_step3.png";
import normalImage from "../../assets/images/image_normal_step3.png";
import overweightImage from "../../assets/images/image_overweight_step3.png";
import { triggerScroll } from "../../utils/message";

class Step3 extends React.PureComponent {
  static contextType = FoodFinder;

  state = {
    currentSelectCard: "underweight"
  };

  componentDidMount() {
    const { weight } = this.props.stepsData;
    if (weight) this.setState({ currentSelectCard: weight });
  }

  onClickNext = () => {
    triggerScroll();
    this.context.setData({ weight: this.state.currentSelectCard });
    this.props.history.push("step4");
  };

  render() {
    const { currentSelectCard } = this.state;
    const { name, gender } = this.props.stepsData;

    return (
      <div className="step-page-container">
        <StepsMap
          currentStep={2}
          lastStep={this.context.getLastStep()}
          navigation={this.props.history}
        />
        <div className="page-container">
          <span
            className="back-link"
            onClick={() => {
              this.props.history.push("step2");
            }}
          >
            <Icon type="left" style={{ fontSize: "16px" }} />
            <span>{" BACK"}</span>
          </span>
          <Header
            title={`How much does ${name} weigh?`}
            subTitle="step 3 of 7"
          />
          <p>
            {`This gives us an idea of whether ${name}’s new food should help 
            ${gender === "male" ? "him" : "her"} put on weight, lose weight or
            stay the same. And the mix for their perfect bowl.`}
          </p>
          <div className="cards-container">
            <SelectCard
              title="underweight"
              isSelected={currentSelectCard === "underweight"}
              image={underweightImage}
              onClick={() => {
                this.setState({ currentSelectCard: "underweight" });
              }}
            />
            <SelectCard
              title="just right"
              isSelected={currentSelectCard === "just right"}
              image={normalImage}
              onClick={() => {
                this.setState({ currentSelectCard: "just right" });
              }}
            />
            <SelectCard
              title="overweight"
              isSelected={currentSelectCard === "overweight"}
              image={overweightImage}
              onClick={() => {
                this.setState({ currentSelectCard: "overweight" });
              }}
            />
          </div>
          <Button type="primary" onClick={this.onClickNext}>
            Next
          </Button>
        </div>
      </div>
    );
  }
}

export default StepRedirect(Step3);
